import { useStaticQuery, graphql } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

const DataProtection = () => {
  const {
    site: {
      siteMetadata: {
        contact: { address },
        privacy: { dpo },
      },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          contact {
            address {
              street
              neighborhood
              city
              state
              country
              zip
            }
          }
          privacy {
            dpo {
              name
              role
              phone
              email
            }
          }
        }
      }
    }
  `);

  const fullAddress = `${address.neighborhood}, ${address.city}, ${address.state} - ${address.country}`;

  return (
    <Layout>
      <SEO />
      <div id="privacy" className="row main-well">
        <h2>
          <FormattedMessage id="privacy.dpo.title" />
        </h2>
        <p>
          <FormattedMessage id="privacy.dpo.disclaimer" />
        </p>
        <p>
          <br />
          <strong>
            <FormattedMessage id="privacy.dpo" />
          </strong>
          <br />
          {dpo.name}
          <br />
          {dpo.role}
          <br />
          <br />
          <strong>
            <FormattedMessage id="privacy.dpo.address" />
          </strong>
          <br />
          {address.street}
          <br />
          {fullAddress}
          <br />
          <br />
          <strong>
            <FormattedMessage id="privacy.dpo.phone" />
          </strong>
          <br />
          {dpo.phone}
          <br />
          <br />
          <strong>
            <FormattedMessage id="privacy.dpo.email" />
          </strong>
          <br />
          {dpo.email}
        </p>
      </div>
    </Layout>
  );
};

export default DataProtection;
